import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { FeatureGroup, Rectangle } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

const editControlProps = {
  draw: {
    polyline: false,
    polygon: false,
    rectangle: false,
    circle: false,
    marker: false,
    circlemarker: false,
  },
  edit: {
    edit: false,
    remove: false,
    poly: false,
    allowIntersection: false,
  },
};

const MapRectangle = ({
  zone,
  salt,
  setZone = () => {},
}) => {
  const theme = useTheme();
  const zoneRef = React.useRef();

  const handleEdit = React.useCallback(
    event => setZone(event.layer.getLatLngs()),
    [setZone],
  );

  const pathOptions = {
    dashArray: '0.1 15',
    fill: false,
    weight: 5,
    color: theme.palette.secondary.main,
  };

  React.useEffect(() => {
    // Force re-draw when salt is updated
    zoneRef.current?.editing?.disable?.();
    zoneRef.current?.editing?.enable?.();
  }, [salt]);

  React.useEffect(() => zoneRef.current?.editing?.enable?.(), []);

  return (
    <FeatureGroup>
      <EditControl
        {...editControlProps}
        onEditResize={handleEdit}
        onEditMove={handleEdit}
      />
      <Rectangle
        bounds={zone}
        ref={zoneRef}
        pathOptions={pathOptions}
        interactive={false}
      />
    </FeatureGroup>
  );
};

export default MapRectangle;
