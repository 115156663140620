import React from 'react';
import clsx from 'clsx';
import path from 'path-browserify';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GlobalDispatchContext } from './GlobalContextProvider';

import MarkdownText from './MarkdownText';
import EditSource from './EditSource';
import Link from './Link';

import useLexique from '../hooks/useLexique';
import useSiteMetadata from '../hooks/useSiteMetadata';

import ABBR from '../settings/abbr.json';

const revABBR = Object.fromEntries(Object.entries(ABBR).map(i => i.reverse()));

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 2, 0, 2),
  },
}));

const LexiqueView = ({ word, className }) => {
  const classes = useStyles();

  const longWord = revABBR[word.split('.').pop()] || word;

  const { htmlAst } = useLexique(longWord) || {};
  const { docHome } = useSiteMetadata();

  const dispatch = React.useContext(GlobalDispatchContext);

  const customComponents = {
    a: ({ href, ...props }) => {
      const customProps = { ...props };

      const isRelativeUrl = !/^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(href);
      const { 1: keyword } = /^\/lexique\/(.*).md$/.exec(path.resolve('lexique', href)) || [];

      if (keyword && !keyword.includes('/')) {
        customProps.onClick = event => {
          event.preventDefault();
          dispatch({ type: 'HELP_OPEN', payload: keyword });
        };
      }

      if (isRelativeUrl) {
        const { 1: docLink } = /(.*).md$/.exec(`${docHome}${path.resolve('lexique', href)}`);

        return <Link to={docLink} {...customProps} target="_blank" />;
      }
      return <Link href={href} {...customProps} />;
    },
  };

  if (htmlAst) {
    return (
      <Box className={clsx(className, classes.root)}>
        <MarkdownText hast={htmlAst} className={classes.main} components={customComponents} />
        <Typography variant="body1" paragraph>
          Éditer ce contenu <EditSource label="sur Gitlab" doc sourcePath={`docs/lexique/${longWord}.md`} />.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={clsx(className, classes.root)}>
      <Typography variant="body1" paragraph>
        Aucune définition n'est disponible pour ce terme.
      </Typography>

      <Typography variant="body1" paragraph>
        Vous pouvez en proposer
        une <EditSource label="sur Gitlab" doc sourcePath={`docs/lexique/${longWord}.md`} />.
      </Typography>
    </Box>
  );
};

export default LexiqueView;
