import React from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { useTranslation } from 'react-i18next';

import useDisplayFields from '../hooks/useDisplayFields';
import useServiceStyles from '../hooks/useServiceStyles';
import FieldDisplay from './FieldDisplay';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCard-root + .MuiCard-root': {
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[600],
      borderRadius: 0,
    },
  },

  thumbnail: {
    width: 150,
    height: 100,
    marginRight: theme.spacing(2),
  },

  placeholder: {
    background: theme.palette.grey[200],
  },

  index: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  serviceTeaser: {
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
}));

const PlatformList = ({ showIndex = false, items, platforms = [] }) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const { classes: srvClasses } = useServiceStyles();

  const {
    provider: { teaser: providerTeaser },
    service: { teaser: serviceTeaser },
  } = useDisplayFields();

  return (
    <Box className={classes.root}>
      {Boolean(items) && Object.values(items).map(({
        provider,
        provider: { slug } = {},
        services,
      }, index) => (
        <Card elevation={0} key={slug}>
          <CardActionArea onClick={() => navigate(`/platform/${slug}`)}>
            <CardContent>
              {showIndex && (
                <Typography className={classes.index} color="textSecondary">
                  {index + 1}
                </Typography>
              )}

              <Grid container>
                <Grid item md={3}>
                  {providerTeaser.fields.map(fieldProps => (
                    <FieldDisplay
                      key={fieldProps.field}
                      subject={provider} // should aggregate summaries
                      {...fieldProps}
                    />
                  ))}

                </Grid>

                <Grid item md={9} container spacing={1}>
                  {services.filter(({ origin }) => origin === 'service').map(service => {
                    const { name: sName, childIndex } = service;

                    return (
                      <Grid
                        item
                        key={`${sName}${childIndex}`}
                        md={3}
                      >
                        <Box
                          className={clsx(
                            classes.serviceTeaser,
                            srvClasses[service['Service type']],
                          )}
                        >
                          {serviceTeaser.fields.map(fieldProps => (
                            <FieldDisplay
                              key={fieldProps.field}
                              subject={service}
                              {...fieldProps}
                            />
                          ))}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}

      {!items && Boolean(platforms.length) && (
        <>
          {platforms.map(provider => (
            <Card elevation={0} key={provider.fields.slug}>
              <CardActionArea onClick={() => navigate(`/platform/${provider.fields.slug}`)}>
                <CardContent>
                  {providerTeaser.fields.map(fieldProps => (
                    <FieldDisplay key={fieldProps.field} subject={provider} {...fieldProps} />
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </>
      )}

    </Box>
  );
};

export default PlatformList;
