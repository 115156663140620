import React from 'react';

import Layout from '../components/Layout';
import useCorrectLanguage from '../hooks/useCorrectLanguage';
import PlatformSearch from '../components/PlatformSearch';

const OAD = ({ pageContext: { language } }) => {
  useCorrectLanguage(language);

  return (
    <Layout
      translationsAvailable
      container={false}
      fluid={false}
      footer={false}
    >
      <PlatformSearch />
    </Layout>
  );
};

export default OAD;
