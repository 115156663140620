import platformSearch from './settings/oad-form.json';

const getFields = (fields = []) => fields.reduce((acc, curr) => (
  (curr.group === true && Array.isArray(curr.fields))
    ? [...acc, ...getFields(curr.fields)]
    : [...acc, curr]
), []);

export const searchFormSettingsArray = getFields(platformSearch.fields);

export const aggregations = searchFormSettingsArray.reduce((acc, curr) => (
  curr.aggregation
    ? { ...acc, [curr.field]: curr.aggregation }
    : acc
), {});

export const searchableFields = searchFormSettingsArray
  .filter(({ searchable }) => searchable)
  .map(({ field }) => field);

export const sortings = searchFormSettingsArray
  .filter(({ sortable }) => sortable)
  .reduce((acc, { field, sortable }) => {
    const currentSortings = {
      [`${field}_asc`]: { field, order: 'asc' },
    };

    if (sortable === 'both') {
      currentSortings[`${field}_desc`] = { field, order: 'desc' };
    }

    return { ...acc, ...currentSortings };
  }, {});

export const searchSettings = { searchableFields, sortings, aggregations };

/**
 * Transform from:
 * `[{ field: 'a', … }, { field: 'b', … }]`
 * into:
 * `{ a: { field: 'a', … }, b: { field: 'b', … } }`
 */
export const searchFormSettings = searchFormSettingsArray.reduce((acc, curr) =>
  ({ ...acc, [curr.field]: curr }), {});

/**
 * Transform from:
 * `{ a: { … }, b: { … } }`
 * into:
 * `{ a: [], b: [] }`
 */
export const transpose = obj => Object.keys(obj)
  .reduce((acc, curr) => ({ ...acc, [curr]: [] }), {});
