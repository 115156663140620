import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Slider,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Backspace as BackspaceIcon } from '@material-ui/icons';

import ABBR from '../settings/abbr.json';
import LexiqueIcon from './LexiqueIcon';
import RangeValue from './RangeValue';

const revABBR = Object.fromEntries(Object.entries(ABBR).map(arr => arr.reverse()));
const unAbbr = str => str.split('.').map(abbr => (revABBR[abbr] || abbr)).join(' - ');

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(2, 0, 1),
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    padding: theme.spacing(1),
    margin: theme.spacing(-0.5, 0),
  },
  checkboxGroup: {
    padding: theme.spacing(1, 2),
  },
  sliderWrapper: {
    padding: theme.spacing(0, 4),
  },

  pristine: {
    opacity: 0.5,
  },
}));

const PlatformSearchWidget = ({
  settings = [],
  buckets = [],
  filters = {},
  classFilters = {},
  rangeFilters = {},
  hasFilters = {},
  handlers = {},
  reset = () => {},
  // ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { field: fieldName, widget, title, empty = 'Aucun', help = {} } = settings;
  const showWidgetTitle = title ?? title !== false;

  const shortFieldName = fieldName.split('.').pop();

  return (
    <>
      {showWidgetTitle && (
        <Typography
          variant="h5"
          className={classes.title}
          data-origin={fieldName}
        >
          {typeof title === 'string' ? t(title) : t(unAbbr(shortFieldName))}

          {help.title && (
            <LexiqueIcon
              word={help.keyword || shortFieldName}
              tooltip={t(unAbbr(shortFieldName))}
            />
          )}

          {(
            widget === 'range'
            && rangeFilters[fieldName]
            && !rangeFilters[fieldName].pristine
          ) && (
            <IconButton
              size="small"
              style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '1.8rem' }}
              onClick={() => reset(fieldName)}
            >
              <BackspaceIcon fontSize="inherit" />
            </IconButton>
          )}
        </Typography>
      )}

      {widget === 'checkbox' && (
        <Paper variant="outlined">
          <FormGroup className={classes.checkboxGroup}>
            {Object.values(buckets).map(bucket => (
              <FormControlLabel
                key={bucket.key}
                control={(
                  <Checkbox
                    checked={filters[fieldName]?.indexOf(bucket.key) > -1 || false}
                    onChange={handlers.handleFilterAsCheckbox(fieldName, bucket.key)}
                    name={bucket.key}
                    className={classes.checkbox}
                    color="primary"
                  />
                )}
                label={(
                  <>
                    {bucket.key}
                    ({bucket.doc_count})
                    {help.values && (
                      <LexiqueIcon
                        word={bucket.key}
                        // tooltip={t(bucket.key)}
                      />
                    )}
                  </>
                )}
              />
            ))}
          </FormGroup>
        </Paper>
      )}

      {widget === 'select' && (
        <Select
          onChange={handlers.handleFilterAsSelect(fieldName)}
          value={filters[fieldName]?.[0] ?? ''}
          variant="outlined"
          fullWidth
        >
          <MenuItem value=""><em>{t(empty)}</em></MenuItem>

          {Object.values(buckets).map(bucket => (
            <MenuItem value={bucket.key} key={bucket.key}>
              {bucket.key} ({bucket.doc_count})
            </MenuItem>
          ))}
        </Select>
      )}

      {widget === 'classes' && (
        <Select
          onChange={handlers.handleFilterAsClasses(fieldName)}
          value={classFilters[fieldName] ?? ''}
          variant="outlined"
          fullWidth
        >
          <MenuItem value=""><em>{t('Toutes')}</em></MenuItem>

          {settings.classes.map((classItem, index) => (
            <MenuItem value={index} key={classItem.label}>
              {classItem.label}
            </MenuItem>
          ))}
        </Select>
      )}

      {widget === 'range' && rangeFilters[fieldName] && (
        <FormControl
          fullWidth
          className={clsx(
            classes.sliderWrapper,
            { [classes.pristine]: rangeFilters[fieldName].pristine },
          )}
        >
          <Slider
            onChange={handlers.handleFilterAsRange(fieldName)}
            valueLabelDisplay={rangeFilters[fieldName].pristine ? 'auto' : 'on'}
            ValueLabelComponent={RangeValue}
            value={rangeFilters[fieldName].values}
            {...rangeFilters[fieldName].props}
            {...settings?.customProps}
          />
        </FormControl>
      )}

      {widget === 'switch' && typeof hasFilters[fieldName] !== 'undefined' && (
        <FormControlLabel
          control={(
            <Switch
              checked={hasFilters[fieldName]}
              onChange={handlers.handleFilterAsSwitch(fieldName)}
              color="primary"
              {...settings?.customProps}
            />
          )}
          labelPlacement="start"
          label={(
            <>
              {t(fieldName)}
              {help?.values && (
                <LexiqueIcon
                  word={typeof help.values !== 'boolean' ? help.values : fieldName}
                  tooltip={t(fieldName)}
                />
              )}
            </>
          )}
        />
      )}
    </>
  );
};

export default PlatformSearchWidget;
