/* eslint-disable import/prefer-default-export */
import { graphql, useStaticQuery } from 'gatsby';

export const useLexique = word => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {
        sourceInstanceName: { eq: "remote-doc" },
        relativeDirectory: { eq: "docs/lexique" }
      }) {
        nodes {
          name
          childMarkdownRemark {
            frontmatter {
              title
            }
            htmlAst
          }
        }
      }
    }
  `);

  const words = data.allFile.nodes
    .map(({ childMarkdownRemark, ...rest }) => ({ ...rest, ...childMarkdownRemark }));

  if (word) {
    return words.find(({ name }) => (name === word));
  }

  return words;
};

export default useLexique;
