import React from 'react';

import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },

  arrow: {
    color: theme.palette.primary.main,
  },

  tooltipPlacementBottom: {
    margin: theme.spacing(1, 0),
  },
}));

const RangeValue = ({ children, open, value }) => {
  const classes = useStyles();

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      arrow
      interactive
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
};

export default RangeValue;
