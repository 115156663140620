import React, { useReducer } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import inside from '@turf/boolean-point-in-polygon';
import { polygon as Lpolygon } from 'leaflet';
import qs from 'qs';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Replay, Close, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { useLocation } from '@gatsbyjs/reach-router';
// import createPersistedReducer from 'use-persisted-reducer';
import ItemsJS from 'itemsjs';

import { useServices, useServiceProviders } from '../hooks/useServices';
import PlatformList from './PlatformList';
import MapFilter from './MapFilter';

import oadForm from '../settings/oad-form.json';
import PlatformMarkers from './PlatformMarkers';
import PlatformSearchWidget from './PlatformSearchWidget';
import { GlobalDispatchContext, GlobalStateContext } from './GlobalContextProvider';
import LexiqueView from './LexiqueView';
import { debounce, isLive, latLngsFromBounds, groupByProvider } from '../helpers';

import {
  searchFormSettingsArray,
  searchSettings,
  transpose,
  searchFormSettings,
  aggregations,
} from '../search-settings';
import MapRectangle from './MapRectangle';
import MapViewportControls from './MapViewportControls';

import colors from '../settings/colors.json';

// const usePersistedReducer = createPersistedReducer('pcsc-state-cache');

const initialPolygon = [
  [
    { lat: 41.38, lng: -5.3 },
    { lat: 51.22, lng: -5.3 },
    { lat: 51.22, lng: 9.8 },
    { lat: 41.38, lng: 9.8 },
  ],
];

const { fields: searchFormTree } = oadForm;

const useStyles = makeStyles(theme => {
  const maxWidth = mq => `@media (max-width: ${theme.breakpoints.values[mq]}px)`;

  return ({
    layout: {
      height: '100%',
      margin: 0,
    },
    col: {
      overflow: 'auto',
      height: '100%',
      [maxWidth('md')]: { height: 'auto' },
    },
    filters: {
      overflowX: 'hidden',
      position: 'relative',

    },

    resetButtonWrapper: {
      position: 'sticky',
      display: 'flex',
      flexDirection: 'row-reverse',
      top: 0,
      right: theme.spacing(3),
      height: theme.spacing(3),
      zIndex: 2,
    },

    resetButton: {
      opacity: 0.75,
    },

    filtersInner: {
      position: 'relative',
      zIndex: 0,
    },

    group: {
      marginTop: theme.spacing(4),
    },
    fieldTitle: {
      margin: theme.spacing(2, 0, 1),
    },
    accordion: {
      '&.Mui-expanded': { margin: 0 },
    },
    accordionSummary: {
      paddingLeft: 0,
      paddingRight: 0,
      '&.Mui-expanded': { minHeight: theme.spacing(4) },
      '& .MuiAccordionSummary-content': { margin: theme.spacing(1, 0) },
    },
    accordionDetails: {
      padding: theme.spacing(0, 0, 1, 0),
    },

    lexique: {
      backgroundColor: theme.palette.grey[200],
      position: 'relative',
    },
    closeHelp: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },

    mapWrapper: {
      position: 'relative',
    },
    map: {
      zIndex: 0,
    },

    wrapper: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      background: theme.palette.grey['200'],

      borderRadius: theme.shape.borderRadius,

      '& .MuiPaper-root': {
        background: 'transparent',
      },
    },

    altWrapper: {
      background: theme.palette.grey[200],
    },

    lightWrapper: {
      background: 'rgba(255, 255, 255, .15)',
      border: '1px solid rgba(255, 255, 255, .5)',
    },

    shadeWrapper: {
      background: 'rgba(0, 0, 0, .05)',
      border: '1px solid rgba(0, 0, 0, .2)',
    },

    ...Object.fromEntries(
      Object.entries(colors['Service type']).map(([type, tints = {}]) => [
        `${type}Wrapper`,
        { background: tints.main },
      ]),
    ),
  });
});

const localReducer = (state, action) => {
  switch (action.type) {
    case 'FILTERS_SET': {
      return (typeof action.payload === 'function')
        ? { ...state, [action.filterType]: action.payload(state[action.filterType]) }
        : { ...state, [action.filterType]: action.payload };
    }
    case 'QUERY_SET': {
      return (typeof action.payload === 'function')
        ? { ...state, query: action.payload(state.query) }
        : { ...state, query: action.payload };
    }
    case 'QUERY_RESET': {
      return { ...state, query: '' };
    }
    case 'POLYGON_SET': {
      const newState = { ...state, polygon: action.payload };
      if (action.force) {
        newState.salt = Math.random();
      }

      return newState;
    }
    case 'POLYGON_RESET': {
      return {
        ...state,
        salt: Math.random(),
        polygon: initialPolygon,
      };
    }
    default:
      return state;
  }
};

const PlatformSearch = ({ className, ...props }) => {
  const { t } = useTranslation();

  const serviceProviders = useServiceProviders();
  const services = useServices();
  const classes = useStyles();

  const queryString = qs.parse(useLocation()?.search.substr(1));
  const debugMode = Object.keys(queryString).includes('debug');

  const mapRef = React.useRef();

  const itemsjs = React.useMemo(() => ItemsJS(services, searchSettings), [services]);

  const [
    localState,
    localDispatch,
  ] = useReducer(localReducer, {
  // ] = usePersistedReducer(localReducer, {
    filters: transpose(aggregations),
    rangeFilters: {},
    hasFilters: {},
    classFilters: {},
    polygon: initialPolygon,
    query: '',
  });

  const { filters, rangeFilters, hasFilters, classFilters, query, polygon } = localState;

  const setFilters = arg => localDispatch({ type: 'FILTERS_SET', filterType: 'filters', payload: arg });
  const setRangeFilters = arg => localDispatch({ type: 'FILTERS_SET', filterType: 'rangeFilters', payload: arg });
  const setHasFilters = arg => localDispatch({ type: 'FILTERS_SET', filterType: 'hasFilters', payload: arg });
  const setClassFilters = arg => localDispatch({ type: 'FILTERS_SET', filterType: 'classFilters', payload: arg });

  const initOrResetFiltersStates = fieldName => {
    searchFormSettingsArray
      .forEach(({ field, widget, defaultValue }) => {
        if (fieldName && fieldName !== field) { return; }

        const setters = {
          range: setRangeFilters,
          classes: setClassFilters,
          switch: setHasFilters,
        };

        if (!Object.prototype.hasOwnProperty.call(setters, widget)) {
          return;
        }

        const setter = setters[widget];

        const genericDefault = {
          classes: '',
          switch: false,
        };

        if (widget === 'range') {
          const values = Array.from(
            new Set(services.map(({ [field]: f }) => f).filter(v => typeof v !== 'undefined')),
          );

          const bounds = !defaultValue
            ? values.reduce(
              (acc, f) => ({
                min: f < acc.min ? f : acc.min,
                max: f > acc.max ? f : acc.max,
              }),
              { min: Infinity, max: -Infinity },
            )
            : {};

          setter(
            previously => ({
              ...previously,
              [field]: {
                values: (defaultValue ?? [bounds.min, bounds.max]),
                pristine: true,
                props: {
                  disabled: values.length < 2,
                  ...bounds,
                  marks: values.length < 2
                    ? null
                    : Object.values(bounds).map(v => ({ label: v, value: v })),
                },
              },
            }),
          );
        } else {
          setter(
            previously => ({
              ...previously,
              [field]: (defaultValue ?? genericDefault[widget]),
            }),
          );
        }
      });
  };

  // Init some input values
  React.useEffect(initOrResetFiltersStates, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetForm = () => {
    const map = mapRef?.current;
    if (map) { map.fitBounds(initialPolygon, { padding: [1, 1] }); }

    localDispatch({ type: 'QUERY_RESET' });
    localDispatch({ type: 'POLYGON_RESET' });
    initOrResetFiltersStates();
    setFilters(transpose(aggregations));
  };

  const handleFilterAsSelect = filterName => ({ target: { value } }) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterName]: value ? [value] : [] }));
  };

  const handleFilterAsClasses = filterName => ({ target: { value: index } }) =>
    setClassFilters(prevFilters => ({ ...prevFilters, [filterName]: index }));

  const handleFilterAsCheckbox = (filterName, filterValue) => ({ target: { checked } }) =>
    setFilters(({ [filterName]: oldFilter, ...rest }) => {
      const filterSet = new Set(oldFilter);

      if (checked) {
        filterSet.add(filterValue);
      } else {
        filterSet.delete(filterValue);
      }

      return {
        ...rest,
        [filterName]: Array.from(filterSet),
      };
    });

  const handleFilterAsRange = filterName => (event, newValue) =>
    setRangeFilters(prevRangeFilters => ({
      ...prevRangeFilters,
      [filterName]: {
        ...prevRangeFilters[filterName],
        pristine: false,
        values: newValue,
      },
    }));

  const handleFilterAsSwitch = filterName => (event, newValue) =>
    setHasFilters(prevRangeFilters => ({
      ...prevRangeFilters,
      [filterName]: newValue,
    }));

  const searchResult = React.useMemo(
    () => {
      const validateRangeFilters = item => Object.entries(rangeFilters)
        .every(([fieldName, { values: [min, max], pristine }]) => {
          const value = item[fieldName] || 0;
          return pristine || (value >= min && value <= max);
        });

      const validateClassFilters = item => Object.entries(classFilters)
        .every(([fieldName, classIndex]) => {
          const classItem = searchFormSettings[fieldName].classes[classIndex];
          const { range: [min, max] } = classItem || { range: [-Infinity, +Infinity] };

          return (
            item[fieldName] >= Number.parseFloat(min)
            && item[fieldName] < Number.parseFloat(max)
          );
        });

      const validateHasFilters = item => Object.entries(hasFilters)
        .every(([fieldName, value]) => (!value || item[fieldName]));

      const validateGeoFilter = item => {
        if (!item.coordinates || !isLive) {
          return false;
        }

        return inside(
          Object.values(item.coordinates).reverse(),
          Lpolygon(polygon).toGeoJSON(),
        );
      };

      return itemsjs.search({
        query,
        filters,
        sort: 'name_asc',
        filter: item => (
          true
          && validateRangeFilters(item)
          && validateHasFilters(item)
          && validateClassFilters(item)
          && validateGeoFilter(item)
        ),
      });
    },
    [itemsjs, query, filters, rangeFilters, classFilters, hasFilters, polygon],
  );

  const widgetCommonProps = {
    filters,
    classFilters,
    rangeFilters,
    hasFilters,
    handlers: {
      handleFilterAsCheckbox,
      handleFilterAsSelect,
      handleFilterAsClasses,
      handleFilterAsRange,
      handleFilterAsSwitch,
    },
    reset: initOrResetFiltersStates,
  };

  const { help } = React.useContext(GlobalStateContext) || {};
  const globalDispatch = React.useContext(GlobalDispatchContext);

  const viewportControls = React.useMemo(() => [
    { control: 'zoomIn', onClick: () => mapRef.current.zoomIn() },
    { control: 'zoomOut', onClick: () => mapRef.current.zoomOut() },
    { control: 'fitZone', onClick: () => mapRef.current.fitBounds(polygon, { padding: [1, 1] }) },
    {
      control: 'zoneToViewport',
      color: 'secondary',
      onClick: () => {
        const asPolygon = latLngsFromBounds(mapRef.current.getBounds());
        localDispatch({ type: 'POLYGON_SET', payload: asPolygon, force: true });
        mapRef.current.fitBounds(asPolygon, { padding: [1, 1] });
      },
    },
    {
      color: 'secondary',
      control: 'resetZone',
      onClick: () => {
        localDispatch({ type: 'POLYGON_SET', payload: initialPolygon, force: true });
        mapRef.current.fitBounds(initialPolygon, { padding: [1, 1] });
      },
    },
  ], [polygon]);

  const resultsByProvider = React.useMemo(
    () => groupByProvider({
      items: searchResult.data.items,
      providers: serviceProviders,
    }),
    [searchResult, serviceProviders],
  );

  if (!isLive) {
    return null;
  }

  const renderWidgetGroup = (tree = []) => {
    if (!tree.length) return null;

    return tree.map(bloc => {
      if (bloc.group === true && Array.isArray(bloc.fields)) {
        return (
          <Box
            key={bloc.title}
            className={clsx(classes.wrapper, {
              [classes[`${bloc.theme || bloc.type}Wrapper`]]: bloc.theme || bloc.type,
            })}
          >
            <Accordion
              elevation={0}
              className={classes.accordion}
              defaultExpanded={bloc.expand}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
              >
                <Typography variant="h4">{t(bloc.title)}</Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.accordionDetails}>
                <Box style={{ flex: 1 }}>
                  {renderWidgetGroup(bloc.fields)}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      }

      if (bloc.widget === 'fulltext') {
        return (
          <Box key="fulltext">
            {bloc.title && (
              <Typography variant="h5" className={classes.fieldTitle}>
                {t(bloc.title)}
              </Typography>
            )}

            <TextField
              label={t('Rechercher')}
              variant="outlined"
              value={query}
              onChange={event => localDispatch({ type: 'QUERY_SET', payload: event.target.value })}
              fullWidth
            />
          </Box>
        );
      }

      if (bloc.field && bloc.widget) {
        return (
          <PlatformSearchWidget
            key={bloc.field}
            settings={bloc}
            buckets={searchResult?.data?.aggregations?.[bloc.field]?.buckets}
            {...widgetCommonProps}
          />
        );
      }
      return null;
    });
  };

  return (
    <Grid container spacing={1} className={clsx(classes.layout, className)} {...props}>

      {/* Search form */}
      <Grid item xs={12} md={3} className={clsx(classes.filters, classes.col)}>
        <Box className={classes.resetButtonWrapper}>
          <Fab onClick={resetForm} size="small" className={classes.resetButton}>
            <Tooltip title={t('Réinitialiser')}><Replay /></Tooltip>
          </Fab>
        </Box>

        <Box className={classes.filtersInner}>
          {renderWidgetGroup(searchFormTree)}
        </Box>
      </Grid>

      {/* Help (or debug) column */}
      {(help || debugMode) && (
        <Grid item xs={12} md={3} className={clsx(classes.col, classes.lexique)}>
          {!debugMode && (
            <>
              <IconButton
                onClick={() => globalDispatch({ type: 'HELP_CLOSE' })}
                className={classes.closeHelp}
              >
                <Close />
              </IconButton>
              <LexiqueView word={help} />
            </>
          )}

          {debugMode && (
            <pre>{JSON.stringify(localState, null, 2)}</pre>
          )}
        </Grid>
      )}

      {/* Map & result list */}
      <Grid item xs={12} md={(help || debugMode) ? 6 : 9} className={classes.col}>
        <Box className={classes.mapWrapper}>
          <MapFilter
            bounds={polygon}
            whenCreated={map => {
              mapRef.current = map;
              map.fitBounds(polygon, { padding: [1, 1] });
            }}
            className={classes.map}
            zoomControl={false}
          >
            <PlatformMarkers items={resultsByProvider} />

            <MapRectangle
              zone={polygon}
              salt={localState.salt}
              setZone={debounce(
                bds => localDispatch({ type: 'POLYGON_SET', payload: bds }),
                100,
              )}
            />

          </MapFilter>

          <MapViewportControls controls={viewportControls} />
        </Box>

        {/* <Typography variant="body1">
          {t('Nombre total de résultats :')} {searchResult.pagination.total}
        </Typography> */}

        <PlatformList items={resultsByProvider} showIndex />
      </Grid>
    </Grid>
  );
};

export default PlatformSearch;
