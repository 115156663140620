import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Fab } from '@material-ui/core';

import {
  AspectRatio,
  SettingsOverscan,
  Replay,
  ErrorOutline,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,

    '& > *': {
      marginBottom: theme.spacing(1),
    },

    '& .MuiFab-root': {
      boxShadow: theme.shadows[2],
    },
  },
}));

const controlIcons = {
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
  resetZone: Replay,
  zoneToViewport: AspectRatio,
  fitZone: SettingsOverscan,
};

const MapViewportControls = ({ className, controls = [], ...props }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(className, classes.root)} {...props}>
      {controls.map(({ control, ...rest }) => {
        const Icon = controlIcons[control] || ErrorOutline;
        return (
          <Box key={control}>
            <Fab color="primary" size="small" {...rest}><Icon /></Fab>
          </Box>
        );
      })}
    </Box>
  );
};

export default MapViewportControls;
