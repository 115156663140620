import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { Marker, Tooltip } from 'react-leaflet';
import { icon, divIcon } from 'leaflet';
import { withPrefix } from 'gatsby';
import { isLive } from '../helpers';

import serverIcon from '../assets/server.svg';
import useServiceStyles from '../hooks/useServiceStyles';

const platformIcon = icon && icon({
  iconUrl: serverIcon,
  iconSize: [32, 32],
  // iconAnchor: [16, 16],
  popupAnchor: [0, -17], // from iconAnchor
  tooltipAnchor: [17, 0], // from iconAnchor
});

const platformIndex = index => divIcon && divIcon({
  html: `<div>${index}</div>`,
  iconSize: [16, 16],
  className: 'number-icon',
  iconAnchor: [0, 0],
});

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  count: {
    display: 'inline-block',
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
  },
}));

const PlatformMarkers = ({ items = {}, ...props }) => {
  const classes = useStyles();
  const { classes: srvClasses } = useServiceStyles();

  return (
    <>
      {Object.values(items).map(({ provider, services = [] }, index) => {
        const { name, coordinates, slug } = provider;

        if (!coordinates) { return null; }
        const { lat, lon } = coordinates;
        if (!lat && !lon) { return null; }

        const counts = services
          .filter(({ origin }) => (origin === 'service'))
          .reduce(
            (acc, { type }) => ({
              ...acc,
              [type]: typeof acc[type] === 'number' ? acc[type] + 1 : 1,
            }),
            {},
          );

        return (
          <React.Fragment key={slug}>
            <Marker
              position={[lat, lon]}
              icon={platformIcon}
              eventHandlers={{
                click: () => (isLive && slug && window.open(withPrefix(`/platform/${slug}`))),
              }}
              {...props}
            >
              <Tooltip>
                <Typography variant="body1" className={classes.title}>
                  {name}
                </Typography>

                {Object.entries(counts).map(([type, count]) => (
                  <Box
                    key={type}
                    component="span"
                    className={clsx(srvClasses[type], classes.count)}
                  >
                    {type} ×{count}
                  </Box>
                ))}
              </Tooltip>
            </Marker>

            <Marker
              position={[lat, lon]}
              icon={platformIndex(index + 1)}
              interactive={false}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PlatformMarkers;
