import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import 'leaflet-draw/dist/leaflet.draw.css';

import Map from './Map';

const useStyles = makeStyles({
  root: {
    height: 0,
    paddingBottom: '40%',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  map: {
    height: '100%',
    minHeight: 0,
  },
});

const MapFilter = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Map className={clsx(classes.map, className)} {...props} />
      </Box>
    </Box>
  );
};

export default MapFilter;
