import React from 'react';

import { Typography } from '@material-ui/core';

// import { makeStyles } from '@material-ui/core/styles';
import Link from './Link';
import { unAbbr } from '../helpers';

// const useStyles = makeStyles(theme => ({
// }));

const FieldDisplay = ({
  subject,
  subject: { slug = subject?.fields?.slug } = {},
  field,
  label = false,
  link = false,
  props,
}) => {
  // const classes = useStyles();
  let content = <></>;

  if (typeof subject[field] === 'undefined') {
    // console.log({ subject, field });
    return null;
  }

  content = (
    <>
      <Typography variant="body2" {...props}>
        {Boolean(label) && (<>{unAbbr(field)} : </>)}
        {subject[field]}
      </Typography>
    </>
  );

  return link ? (
    <Link prefix to={`/platform/${slug}`} target="_blank">
      {content}
    </Link>
  ) : content;
};

export default FieldDisplay;
